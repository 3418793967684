<template>
  <v-container fluid>
    <v-row v-if="isResponsive">
      <v-col cols="12">
        <div class="display-flex align-items-center">
          <p class="text-title mon-bold">{{ texts.article.textArticle }}</p>
          <v-spacer></v-spacer>
          <v-btn
            @click="addArticle"
            class="button-primary mon-bold"
            elevation="0"
            icon
            width="40px !important"
            height="40px !important"
          >
            <v-icon size="18px">mdi-plus-thick</v-icon>
          </v-btn>
        </div>
        <div class="display-flex align-items-center mt-7">
          <v-text-field
            v-model="sSearch"
            type="text"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-4"
            :placeholder="texts.article.search"
            persistent-placeholder
            clearable
            append-icon="mdi-magnify"
            maxLength="100"
          ></v-text-field>
          <article-filter-layout :texts="texts" />
        </div>
        <div class="mt-4">
          <v-select
            v-model="sCategoryIdFilter"
            :label="texts.article.textCatalog"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular"
            :items="aCatalogs"
            item-text="sName"
            item-value="sCategoryId"
            clearable
            @change="returnSubcatalogs"
          ></v-select>
        </div>
        <div class="mt-4">
          <v-select
            v-model="sSubcategoryIdFilter"
            :label="texts.article.textSubcatalog"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular"
            :items="aSubcatalogs"
            item-text="sSubcategoryName"
            item-value="sSubcategoryId"
            clearable
            @change="returnCatalogs"
          ></v-select>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        md="3"
      >
        <p class="text-title mon-bold">{{ texts.article.textArticle }}</p>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <div class="convert-display-block display-flex align-items-center">
          <v-text-field
            v-model="sSearch"
            type="text"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-2"
            :placeholder="texts.article.search"
            persistent-placeholder
            clearable
            append-icon="mdi-magnify"
            maxLength="100"
          ></v-text-field>
          <v-select
            v-model="sCategoryIdFilter"
            :label="texts.article.textCatalog"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-2"
            :items="aCatalogs"
            item-text="sName"
            item-value="sCategoryId"
            clearable
            @change="returnSubcatalogs"
          ></v-select>
          <v-select
            v-model="sSubcategoryIdFilter"
            :label="texts.article.textSubcatalog"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-2"
            :items="aSubcatalogs"
            item-text="sSubcategoryName"
            item-value="sSubcategoryId"
            clearable
            @change="returnCatalogs"
          ></v-select>
          <article-filter-layout :texts="texts" />
          <v-btn
            @click="addArticle"
            class="button-add mon-bold ml-2"
            elevation="0"
          >
            {{ texts.article.textButtonAdd }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ArticleSearchbarLayout",
  props: {
    texts: {
      typ: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aSubcatalogs: [],
      aCatalogs: [],
      sSearch: "",
      sCategoryIdFilter: "",
      sSubcategoryIdFilter: "",
      isResponsive: false,
    };
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
    this.$store.commit("setCategoryIdFilter", "");
    this.$store.commit("setSubcategoryIdFilter", "");

    this.getCatalogs();
    this.getSubcatalogs();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    +this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    addArticle: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}`,
        config
      )
        .then((response) => {
          let arr = response.data.results;
          if (arr.bVerified) {
            DB.get(
              `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {},
              }
            )
              .then((response) => {
                let arr = response.data.results;
                if (arr.length > 0) {
                  this.$router.push({
                    name: "NewArticle",
                  });
                  // this.$store.commit("setArticleDialogAdd", true);
                } else {
                  this.mixInfo(this.texts.article.withoutWarehouse);
                }
              })
              .catch((error) => {
                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            this.mixError(this.texts.article.isVerifiedText);
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getCatalogs: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {},
        params: {},
      })
        .then((response) => {
          this.aCatalogs = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    returnSubcatalogs: function () {
      this.getSubcatalogs();
    },
    returnCatalogs: function (e) {
      if (e !== null) {
        this.sCategoryIdFilter = this.aSubcatalogs.find((res) => {
          return res.sSubcategoryId == e;
        }).sCategoryId;
      }
    },
    getSubcatalogs: function (e) {
      let res;
      if (this.sCategoryIdFilter == null) {
        res = "";
      } else {
        res = this.sCategoryIdFilter;
      }

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/subcategories?sCategoryId=${res}`,
        {
          headers: {},
          params: {},
        }
      )
        .then((response) => {
          this.aSubcatalogs = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getCatalogs();
        this.getSubcatalogs();
      }
    },
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 300),
    sCategoryIdFilter: function () {
      this.sCategoryIdFilter == null
        ? this.$store.commit("setCategoryIdFilter", "")
        : this.$store.commit("setCategoryIdFilter", this.sCategoryIdFilter);
    },
    sSubcategoryIdFilter: function () {
      this.sSubcategoryIdFilter == null
        ? this.$store.commit("setSubcategoryIdFilter", "")
        : this.$store.commit(
            "setSubcategoryIdFilter",
            this.sSubcategoryIdFilter
          );
    },
  },
};
</script>

<style scoped>
.button-add {
  height: 40px !important;
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.text-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .convert-display-block {
    display: block;
  }
}
</style>